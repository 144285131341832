import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CartItem } from '../models/cart-item';
import { Item } from '../models/item';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private itemsInCartSubject: BehaviorSubject<CartItem[]> = new BehaviorSubject( [] );
  public itemsInCart: CartItem[] = [];

  constructor() {
    this.itemsInCartSubject.subscribe(_ => this.itemsInCart = _);
    this.getStorageItems()
  }

  async getStorageItems(){
    const storageItems = await this.getStorageData()
    if(storageItems){
      this.setItems(storageItems)
    }
  }

  public async addToCart( item: CartItem ) {
    const newCart = [...this.itemsInCart, item]
    await this.setStorageItem(newCart)
    this.itemsInCartSubject.next( newCart );
  }

  public getItems(): Observable<CartItem[]> {
    return this.itemsInCartSubject;
  }

  public async setItem( item: CartItem ) {
    const currentItems = [...this.itemsInCart];
    const itemsWithoutRemoved = currentItems.filter( _ => _.item.createAt !== item.item.createAt );
    await this.setStorageItem(itemsWithoutRemoved)
    this.itemsInCartSubject.next( itemsWithoutRemoved );
    this.addToCart( item );
  }

  public async setItems(items: CartItem[]){
    await this.setStorageItem(items)
    this.itemsInCartSubject.next(items);
  }

  public async removeFromCart(item: Item) {
    const currentItems = [...this.itemsInCart];
    const itemsWithoutRemoved = currentItems.filter(_ => _.item.createAt !== item.createAt);
    await this.setStorageItem(itemsWithoutRemoved)
    this.itemsInCartSubject.next(itemsWithoutRemoved);
  }

  public async removeAllCart() {
    this.itemsInCart = [];
    await this.setStorageItem(this.itemsInCart)
    this.itemsInCartSubject.next( this.itemsInCart );
  }

  public async removeStoreItems(businessId){
    const currentItems = [...this.itemsInCart];
    const itemsWithoutRemoved = currentItems.filter(ci=>ci.item.businessId!=businessId);
    await this.setStorageItem(itemsWithoutRemoved)
    this.itemsInCartSubject.next( itemsWithoutRemoved );
  }

  private async setStorageItem(item){
    /* if(this.platform.is('cordova')){
      return await this.nativeStorage.setItem('cashinItemsInCart',item)
    }else{ */
      return localStorage.setItem('ataviarItemsInCart',JSON.stringify(item))
    /* } */
  }

  private async getStorageData(){
    /* if(this.platform.is('cordova')){
      return await this.nativeStorage.getItem('cashinItemsInCart')
    }else{ */
      return JSON.parse(localStorage.getItem('ataviarItemsInCart'))
    /* } */
  }
}
