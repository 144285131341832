import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LoadingController, ModalController, NavController, ToastController } from '@ionic/angular';
import { TweenMax, Expo } from "gsap/all";
import { switchMap } from 'rxjs/operators';
import { B_SHEDULE } from 'src/app/mocks/backgrounds';
import { POSITIONS } from 'src/app/mocks/daytime';
import { Business } from 'src/app/models/business';
import { User } from 'src/app/models/user';
import { AdvisorsService } from 'src/app/services/advisors.service';
import { AuthService } from 'src/app/services/auth.service';
import { BusinessService } from 'src/app/services/business.service';
import { CartService } from 'src/app/services/cart.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
})
export class ScheduleComponent implements OnInit {

  @ViewChild('sliderTime') sliderTime: ElementRef;

  //Variables de entorno
  mainInterval: any
  //Fecha Actual
  ahora:Date = new Date()
  date: Date;
  //Validador de días en ESP
  aMonths: string[] = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"]
  aDays: string[] = ["DOM", "LUN", "MAR", "MIE", "JUE", "VIE", "SAB"]
  //Validador de días en ESP
  aCompleteMonths: string[] = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"]
  aCompleteDays: string[] = ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"]
  //Fecha máxima y mīnima de atención
  minDate: Date;
  maxDate: Date;

  positions = POSITIONS
  backgrounds = B_SHEDULE
  background: string

  advisor: User
  advisors: User[]

  selectedTiempo: Date | null
  //Negocio
  business: Business
  //configuracion del ngocio
  minSelectedDayOpenTime: number
  maxSelectedDayOpenTime: number
  //Validador de horas/minutos
  //variables de entorno
  availableButton: boolean = true
  toast: HTMLIonToastElement;
  loader: HTMLIonLoadingElement;
  user: User
  //HELPERS
  auxMinMinutes:number
  auxMaxMinutes:number
  auxMinBookingDate:Date
  auxMaxBookingDate:Date

  constructor(
    private modalCtrl: ModalController,
    private businessService: BusinessService,
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController,
    private orderService: OrderService,
    private authService: AuthService,
    private cartService: CartService,
    private navCtrl: NavController,
    private advisorService: AdvisorsService
  ) {
    this.background = this.backgrounds[Math.floor(Math.random() * this.backgrounds.length)];
  }

  ngOnInit() {
    this.authService.user$.subscribe(user=>this.user=user)
    this.businessService.selectedBusines$.pipe(
      switchMap(business=>{
        if(business){
          this.business = business[0]
          return this.advisorService.getAdvisorBusiness(business[0].createAt)
        }
      })
    ).subscribe(advisors=>this.advisors=advisors)
    this.setTimeNow()
  }

  async selectTime(){
    this.orderService.myOrder.bookingDate = this.selectedTiempo.getTime()
    await this.modalCtrl.dismiss()
  }

  setTimeNow(){
    this.mainInterval = setInterval(() => {
      //Agregarmos valor a las vriables
      this.date = new Date();
      this.minDate = new Date();
      this.maxDate = new Date();
      //Definimos el valor máximo de agenda en 15 días postriores
      this.maxDate = new Date(this.maxDate.setDate(this.date.getDate() + 15))
      //De la hora actual obtener los minutos y segundos
      const minutesFromActualDate = this.date.getMinutes()
      const hoursFromActualDate = this.date.getHours()
      //Si las horas del día actual son superiores a 20... se suma al día uno con el fin de no poder seleccionar dicho día
      if((hoursFromActualDate >= Math.floor(this.business.schedule[this.date.getDay()].close / 60)) || !this.business.schedule[this.date.getDay()].active){
        let newAvailableDay = new Date(this.minDate.setDate(this.minDate.getDate() + 1))
        while (!this.business.schedule[newAvailableDay.getDay()].active) {
          console.log('while')
          newAvailableDay = new Date(newAvailableDay.setDate(this.minDate.getDate() + 1))
        }
        this.minDate = newAvailableDay
        if(!this.selectedTiempo) {
          this.selectedTiempo = newAvailableDay
          this.selectedTiempo = new Date(this.selectedTiempo.setHours(0, 0, 0, 0))
        }
      }else{
        if(!this.selectedTiempo) {
          this.selectedTiempo = new Date()
          this.selectedTiempo = new Date(this.selectedTiempo.setHours(0, 0, 0, 0))
        }
      }
      //Si se ha seleccionado una fecha se aplican las siguientes 
      if(this.selectedTiempo){
        //Sacamos la hora mínima y máxima del día seleccionado
        this.minSelectedDayOpenTime = this.business.schedule[this.selectedTiempo.getDay()].open
        this.maxSelectedDayOpenTime = this.business.schedule[this.selectedTiempo.getDay()].close
        //Sí la fecha elegida es igual a la fecha de hoy
        var cadena1 = this.date.getFullYear().toString()+this.date.getMonth().toString()+this.date.getDate().toString(),
        cadena2 = this.selectedTiempo.getFullYear().toString()+this.selectedTiempo.getMonth().toString()+this.selectedTiempo.getDate().toString()
        if(cadena1 == cadena2){
          //console.log('son iguales los dias')
          //Si la hora es mayor o igual al minimo permitido este dia y menor o igual al maximo permitido este dia
          if(hoursFromActualDate >= (this.business.schedule[this.selectedTiempo.getDay()].open / 60) && hoursFromActualDate <= (this.business.schedule[this.selectedTiempo.getDay()].close / 60)){
            //console.log('esta dentro del rango de horas permitido')
            //creamos una variable que marque de manera individual los minutos
            const min = ((hoursFromActualDate)*60) + minutesFromActualDate + 30
            //Creamos un ciclo para determinar los minutos que han transcurrido durante el día
            for (var i = 0; i < this.maxSelectedDayOpenTime; i+= 30) {
              //Creamos una validación condicional para saber ubicar el valor min entre los 360 y 1260 minutos
              if(min >= i && min <= (i+30)){
                //Cuando encontramos el valor le sumamos 15 minutos para adelantarnos
                this.minSelectedDayOpenTime = i + 30
                //vlidamos que la primera vez el valor de horas y minutos este en cero para setearlo
                if(this.selectedTiempo.getHours() === 0 && this.selectedTiempo.getMinutes() === 0){
                  this.selectedTiempo = new Date(this.selectedTiempo.setMinutes(this.selectedTiempo.getMinutes() + this.minSelectedDayOpenTime) )
                }else{
                  const actualTime = (this.selectedTiempo.getHours()*60)+this.selectedTiempo.getMinutes()
                  if(actualTime < this.minSelectedDayOpenTime){
                    this.selectedTiempo = new Date(this.selectedTiempo.setMinutes(this.selectedTiempo.getMinutes() + this.minSelectedDayOpenTime) )
                  }
                  //revisar
                }
                //console.log(this.minSelectedDayOpenTime)
                /* this.entrada = new Date(this.date)
                this.entrada.setHours(0,0,0,0);
                this.entrada.setMinutes(this.entrada.getMinutes() + this.min) */
              }
            }
          }else{
            if(this.selectedTiempo.getHours() == 0 && this.selectedTiempo.getMinutes() == 0){
              this.selectedTiempo.setMinutes(this.minSelectedDayOpenTime)
            }
          }
        }else{
          //Cambiamos el valor si estamos en el mismo día y estamos entre el tiempo requerido
          if(this.selectedTiempo.getHours() == 0 && this.selectedTiempo.getMinutes() == 0){
            this.selectedTiempo.setMinutes(this.minSelectedDayOpenTime)
          }
        }

      }
    }, 1000);
  }

  formatAMPM(date:Date):string {
    let hours:number = date.getHours();
    let minutes:number = date.getMinutes();
    let ampm:string = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    let setMinutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + setMinutes + ' ' + ampm;
    return strTime;
  }

  getPaymentValue(): number {
    return this.formatNumber((this.getTotalCost() + (this.getTotalCost() * .12)))
  }

  getTotalCost(): number {
    return this.getBusinessItems().reduce((a, b) => a + (b.item.price * b.quantity), 0)
  }

  getDuration(): number {
    return this.getBusinessItems().reduce((a, b) => a + (b.item.duration * b.quantity), 0)
  }

  getBusinessItems(){
    return this.cartService.itemsInCart.filter(ci=>ci.item.businessId===this.business.createAt)
  }

  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return this.business?.schedule[day].active === true;
  };

  back(){
    this.modalCtrl.dismiss()
  }

  setBackground(event){
    TweenMax.to(".page", 0, {
      backgroundImage: 'linear-gradient(0deg, '+ this.positions[event.target.value].light +' 0%, '+ this.positions[event.target.value].dark +' 90%)',
      ease: Expo.easeInOut,
    })
    TweenMax.to(".sun", 0, {
      x: this.positions[event.target.value].x,
      y: this.positions[event.target.value].y,
      ease: Expo.easeInOut,
      opacity: this.positions[event.target.value].sun,
    });
    TweenMax.to(".moon", 0, {
      x: this.positions[event.target.value].x,
      y: this.positions[event.target.value].y,
      ease: Expo.easeInOut,
      opacity: this.positions[event.target.value].moon,
    });
    this.setMinutesBasedOnNumber(event.target.value)
  }

  selectDate(event){
    this.advisor = null
    var cadena1 = this.date.getFullYear().toString()+this.date.getMonth().toString()+this.date.getDate().toString(),
    cadena2 = this.selectedTiempo.getFullYear().toString()+this.selectedTiempo.getMonth().toString()+this.selectedTiempo.getDate().toString()
    if(cadena1 != cadena2){
      this.sliderTime.nativeElement.value = this.business.schedule[this.selectedTiempo.getDay()].open
    }
    /* ANIMATION */
    TweenMax.from(".date", .8, {
      x: -10,
      ease: Expo.easeInOut,
      opacity: 0,
    });
    TweenMax.from(".hours", .8, {
      x: 10,
      ease: Expo.easeInOut,
      delay: .3,
      opacity: 0,
    });
  }

  getMinMinutes(date:Date){
    return (date?.getHours() * 60) + date?.getMinutes()
  }

  setScene(event){
    this.advisor = null
    this.setMinutesBasedOnNumber(event.target.value)

    TweenMax.to(".sun", .3, {
      x: this.positions[event.target.value].x,
      y: this.positions[event.target.value].y,
      ease: Expo.easeInOut,
      opacity: this.positions[event.target.value].sun,
    });
    TweenMax.to(".moon", .3, {
      x: this.positions[event.target.value].x,
      y: this.positions[event.target.value].y,
      ease: Expo.easeInOut,
      opacity: this.positions[event.target.value].moon,
    });
    TweenMax.to(".page", .3, {
      backgroundImage: 'linear-gradient(0deg, '+ this.positions[event.target.value].light +' 0%, '+ this.positions[event.target.value].dark +' 90%)',
      ease: Expo.easeInOut,
    })
    /* TweenMax.from(".hours", 1, {
      x: 20,
      ease: Expo.easeInOut,
      opacity: 0,
    }); */
  }

  ionViewDidEnter(){
    this.showEnterDataAnimation()
  }

  closeScheduleAdvisor(advisor?:User){
    if (advisor) this.advisor = advisor
    TweenMax.to(".schedule_advisor", .3, {
      y: '100%',
      ease: Expo.easeInOut,
      opacity: 1
    });
  }

  closeScheduleConfirm(){
    TweenMax.to(".schedule_confirm", .3, {
      y: '100%',
      ease: Expo.easeInOut,
      opacity: 1
    });
  }

  ionViewDidLeave(){
    clearInterval(this.mainInterval)
  }

  private showEnterDataAnimation(){
    /* INFO */
    TweenMax.from(".select_btn", .8, {
      y: -20,
      ease: Expo.easeInOut,
      delay: .8,
      opacity: 0,
    });
    TweenMax.from(".date", .8, {
      x: -20,
      ease: Expo.easeInOut,
      delay: .6,
      opacity: 0,
    });
    TweenMax.from(".hours", .8, {
      x: 20,
      ease: Expo.easeInOut,
      delay: .8,
      opacity: 0,
    });
    /* CLOCK */
    TweenMax.from("mat-calendar", .8, {
      y: -20,
      ease: Expo.easeInOut,
      delay: .8,
      opacity: 0,
    });
    TweenMax.from(".range", .8, {
      y: 20,
      ease: Expo.easeInOut,
      delay: .8,
      opacity: 0,
    });
    /* SPHERES */
    TweenMax.to(".sun", .8, {
      x: -120,
      y: 0,
      ease: Expo.easeInOut,
      opacity: 1
    });
    TweenMax.to(".moon", 0, {
      x: -120,
      y: -120,
      ease: Expo.easeInOut,
      opacity: 0
    });
    /* BACKGROUND GRADIENT */
    TweenMax.to(".page", 0, {
      backgroundImage: 'linear-gradient(0deg, #82b0d9 0%, #4b89bf 90%)',
      ease: Expo.easeInOut,
    })
    //BACK BTN
    TweenMax.from(".close_btn_datepicker", 1, {
      opacity: 0,
      x: 120,
      ease: Expo.easeOut,
      delay:.3
    })
  }

  private setMinutesBasedOnNumber(number:number){
    this.selectedTiempo = new Date(this.selectedTiempo.setHours(0, 0, 0, 0))
    this.selectedTiempo = new Date(this.selectedTiempo.setMinutes(this.selectedTiempo.getMinutes() + number) )
  }

  private formatNumber(number:number):number{
    return Number(number.toFixed(2))
  }

}
