import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { switchMap } from 'rxjs/operators';
import { Business } from 'src/app/models/business';
import { Rol } from 'src/app/models/rol';
import { BusinessService } from 'src/app/services/business.service';
import { CartService } from 'src/app/services/cart.service';
import { OrderService } from 'src/app/services/order.service';
import { RolService } from 'src/app/services/rol.service';
import { ThemeService } from 'src/app/services/theme.service';
import { validateSchedule } from 'src/app/utils/shedule';

@Component({
  selector: 'app-advisors',
  templateUrl: './advisors.component.html',
  styleUrls: ['./advisors.component.scss'],
})
export class AdvisorsComponent implements OnInit {

  business: Business
  rols: Rol[]

  constructor(
    private businessService: BusinessService,
    private rolService: RolService,
    private modalCtrl: ModalController,
    public themeService: ThemeService,
    private orderService: OrderService,
    private cartService: CartService
  ) {
    const source = this.businessService.selectedBusines$.pipe(
      switchMap(business=>{
        if(business){
          this.business = business[0]
          return this.rolService.getBusinessRols(business[0].createAt)
        }
      })
    )
    const sourceUser = this.rolService.joinUsers(source)
    this.rolService.joinSchedule(sourceUser).subscribe(rols=>this.rols=rols)
  }

  ngOnInit() {}

  async back(){
    await this.modalCtrl.dismiss()
  }

  schedulesAdvisors(rols:Rol[]){
    if(this.rols){
      return rols.filter((rol:Rol)=>
        this.orderService.myOrder.bookingDate ? !this.validateSchedule(rol) : false
      )
    }
  }

  validateSchedule(rol: Rol):boolean{
    return validateSchedule(rol.schedules,this.getTimeDate(this.orderService.myOrder.bookingDate)?.getTime(),this.getDuration())
  }

  getTimeDate(timestamp:number):Date{
    return new Date(timestamp)
  }

  getDuration(): number {
    return this.getBusinessItems().reduce((a, b) => a + (b.item.duration * b.quantity), 0)
  }

  getBusinessItems(){
    return this.cartService.itemsInCart.filter(ci=>ci.item.businessId===this.business?.createAt)
  }

  async closeScheduleAdvisor(rol:Rol){
    await this.modalCtrl.dismiss({
      advisor: rol.user
    })
  }

}
