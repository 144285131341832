import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController, NavController, ToastController } from '@ionic/angular';
import { Business } from 'src/app/models/business';
import { User } from 'src/app/models/user';
import { TweenMax, Expo } from "gsap/all";
import { AuthService } from 'src/app/services/auth.service';
import { BusinessService } from 'src/app/services/business.service';
import { ThemeService } from 'src/app/services/theme.service';
import { OrderService } from 'src/app/services/order.service';
import { CartService } from 'src/app/services/cart.service';
import { Schedule } from 'src/app/models/schedule';
import { Item } from 'src/app/models/item';
import { ScheduleComponent } from '../schedule/schedule.component';
import { getRangeTime } from 'src/app/utils/shedule';
import { ScheduleService } from 'src/app/services/schedule.service';
import { Rol } from 'src/app/models/rol';
import { AdvisorsComponent } from '../advisors/advisors.component';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {

  /* TEST */
  lat = 51.678418;
  lng = 7.809007;
  /* Entorno */
  availableButton: boolean = true
  toast: HTMLIonToastElement;
  loader: HTMLIonLoadingElement;
  /* Entidades */
  user: User
  business: Business
  advisor: User
  rols: Rol[]
  /* Helpers */
  auxMinMinutes:number
  auxMaxMinutes:number
  auxMinBookingDate:Date
  auxMaxBookingDate:Date
  //Validador de días en ESP
  aMonths: string[] = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"]
  aDays: string[] = ["DOM", "LUN", "MAR", "MIE", "JUE", "VIE", "SAB"]
  //Validador de días en ESP
  aCompleteMonths: string[] = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"]
  aCompleteDays: string[] = ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"]

  constructor(
    private modalCtrl: ModalController,
    public themeService: ThemeService,
    private businessService: BusinessService,
    private authService: AuthService,
    public cartService: CartService,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    public orderService: OrderService,
    private loadingCtrl: LoadingController,
    private scheduleService: ScheduleService,
  ) {
    this.businessService.selectedBusines$.subscribe(
      business=>{
        if(business){
          this.business = business[0]
        }
      }
    )
  }

  ngOnInit() {
    this.authService.user$.subscribe(user=>this.user=user)
  }

  async sendOrder(){
    TweenMax.to(".schedule_confirm", .3, {
      y: '100%',
      ease: Expo.easeInOut,
      opacity: 1
    });
    this.availableButton = false
    this.loader = await this.loadingCtrl.create({
      message: 'Realizando pedido...',
      duration: 2000
    });
    await this.loader.present();
    try {
      this.auxMinMinutes = 0
      this.auxMaxMinutes = 0
      this.auxMinBookingDate = new Date(this.orderService.myOrder.bookingDate)
      this.orderService.myOrder = {
        userID: this.user.uid,
        createAt: Date.now(),
        price: this.getPaymentValue(),
        orderStatus: 2,
        itemsInCart: this.getBusinessItems().map((cartItem,i)=>{
          cartItem.advisorID = this.advisor ? this.advisor.uid : null
          cartItem.status = this.advisor ? 2 : 1
          this.auxMaxBookingDate = new Date(this.orderService.myOrder.bookingDate)
          cartItem.startAt = this.auxMinBookingDate.setMinutes(this.auxMinBookingDate.getMinutes() + this.auxMinMinutes)
          this.auxMinMinutes = this.auxMinMinutes + cartItem.item.duration
          this.auxMaxMinutes = this.auxMaxMinutes + cartItem.item.duration
          cartItem.endAt = this.auxMaxBookingDate.setMinutes(this.auxMaxBookingDate.getMinutes() + this.auxMaxMinutes)
          return cartItem
        }),
        rate: {
          rated: false,
          value: null
        },
        businessID: this.business.createAt,
        payments: {
          subtotal: this.getTotalCost(),
          iva: this.formatNumber(this.getTotalCost() * .12)
        },
        bookingDate: this.orderService.myOrder.bookingDate,
        duration: this.getDuration(),
        lat: this.business.g.geopoint.latitude,
        lng: this.business.g.geopoint.longitude
      }
      await this.orderService.createOrder()
      this.orderService.myOrder.itemsInCart.forEach(async(v)=>{
        const schedule: Schedule = {
          startAt: v.startAt,
          endAt: v.endAt,
          orderId: this.orderService.myOrder.createAt,
          active: true,
          businessId: this.business.createAt,
          advisorId: this.advisor ? this.advisor.uid : null,
          duration: v.item.duration
        }
        await this.scheduleService.setSchedule(schedule)
      })
      this.orderService.resetOrder()
      this.cartService.removeStoreItems(this.business.createAt)
      await this.navCtrl.navigateForward('/home/appointments')
      this.back()
      await this.loader.dismiss()
      this.toast = await this.toastCtrl.create({
        message: 'Compra realizada con éxito.',
        duration: 2000,
        color: 'success'
      });
      this.toast.present();
      this.availableButton =  true
    } catch (error) {
      await this.loader.dismiss()
      this.toast = await this.toastCtrl.create({
        message: 'No se ha podido procesar la compra.',
        duration: 2000,
        color: 'warning'
      });
      this.toast.present();
      this.availableButton = true
    }
  }

  getPaymentValue(): number {
    return this.formatNumber((this.getTotalCost() + (this.getTotalCost() * .12)))
  }

  getTotalCost(): number {
    return this.getBusinessItems().reduce((a, b) => a + (b.item.price * b.quantity), 0)
  }

  getDuration(): number {
    return this.getBusinessItems().reduce((a, b) => a + (b.item.duration * b.quantity), 0)
  }

  getBusinessItems(){
    return this.cartService.itemsInCart.filter(ci=>ci.item.businessId===this.business?.createAt)
  }

  deleteItem(item:Item){
    this.cartService.removeFromCart(item)
  }

  async openSchedule() {
    if(this.getBusinessItems().length > 0){
      const modal = await this.modalCtrl.create({
        component: ScheduleComponent,
        backdropDismiss: false
      });
      return await modal.present();
    }
  }

  async getRangeTime(){
    getRangeTime(this.orderService.myOrder.bookingDate)
  }

  getTimeDate(timestamp:number):Date{
    return new Date(timestamp)
  }

  async back(){
    return await this.modalCtrl.dismiss()
  }

  private formatNumber(number:number):number{
    return Number(number.toFixed(2))
  }

  formatAMPM(date:Date):string {
    let hours:number = date.getHours();
    let minutes:number = date.getMinutes();
    let ampm:string = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    let setMinutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + setMinutes + ' ' + ampm;
    return strTime;
  }

  async scheduleAdvisor(){
    if(this.orderService.myOrder.bookingDate) {
      const modal = await this.modalCtrl.create({
        component: AdvisorsComponent,
        backdropDismiss: false
      });
      await modal.present();
      const { data } = await modal.onWillDismiss();
      if(data?.advisor) this.advisor = data.advisor
    }else{
      const toast = await this.toastCtrl.create({
        message: 'Primero selecciona fecha para ver disponibilidad.',
        duration: 3000,
        color: 'warning'
      });
      toast.present();
    }
  }

  async scheduleConfirm(){
    if(this.orderService.myOrder.bookingDate && this.advisor) {
      TweenMax.to(".schedule_confirm", .3, {
        y: '-100%',
        ease: Expo.easeInOut,
        opacity: 1
      });
    }else{
      const toast = await this.toastCtrl.create({
        message: 'Primero completa la información para agendar.',
        duration: 3000,
        color: 'warning'
      });
      toast.present();
    }
  }

  closeScheduleConfirm(){
    TweenMax.to(".schedule_confirm", .3, {
      y: '100%',
      ease: Expo.easeInOut,
      opacity: 1
    });
  }

  availableForm():boolean {
    if(this.availableButton && this.advisor && this.orderService.myOrder.bookingDate){
      return true
    }
    return false
  }

}
