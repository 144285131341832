import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-web-image',
  templateUrl: './web-image.component.html',
  styleUrls: ['./web-image.component.scss'],
})
export class WebImageComponent implements OnInit {

  @Input() maxWidth: number;
  @Input() title: string;
  @Input() imageData?: string;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  toast: HTMLIonToastElement;

  constructor(
    public modalCtrl: ModalController,
    private toastCtrl: ToastController
  ) { }

  ngOnInit() {
    if(this.imageData){
      this.fileChangeEvent(this.imageData)
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  async loadImageFailed() {
    this.toast = await this.toastCtrl.create({
      message: 'No se ha podido cargar la imagen',
      color: 'danger',
      duration: 2000
    });
    await this.toast.present();
  }

  onCropImage(){
    this.modalCtrl.dismiss({
      'croppedImage': this.croppedImage
    });
  }

}
