import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Business } from '../models/business';
import { Order } from '../models/order';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  myOrder: Order = {
    userID: null,
    createAt: null,
    price: null,
    orderStatus: 1,
    itemsInCart: [],
    rate: null,
    businessID: null,
    payments: null,
    bookingDate: null,
    duration: null,
    lat: null,
    lng: null
  }

  constructor(
		private afs: AngularFirestore
  ) { }

  async createOrder():Promise<void>{
    const ref: AngularFirestoreDocument<Order> = this.afs.doc(`orders/${this.myOrder.createAt}`);
		return await ref.set(this.myOrder, { merge: true })
  }

  async setOrder(order:Order):Promise<void>{
    const starPath = `orders/${order.createAt}`;
    return this.afs.doc(starPath).set(order)
  }

  getOrderById(orderId:number):Observable<Order>{
    const ref: AngularFirestoreDocument<Order> = this.afs.doc(`orders/${orderId}`)
    return ref.valueChanges()
  }

  getUserOrders(uid: string):Observable<Order[]>{
    const ref: AngularFirestoreCollection<Order> = this.afs.collection(`orders`,ref => ref
      .where('usserID','==',uid)
    )
    return ref.valueChanges()
  }

  getUserPendingOrders(uid: string):Observable<Order[]>{
    const ref: AngularFirestoreCollection<Order> = this.afs.collection(`orders`,ref => ref
      .where('userID','==',uid)
      .where('orderStatus','in',[1,2,3])
    )
    return ref.valueChanges()
  }

  getUserFinishedOrders(uid: string):Observable<Order[]>{
    const ref: AngularFirestoreCollection<Order> = this.afs.collection(`orders`,ref => ref
      .where('userID','==',uid)
      .where('orderStatus','in',[4])
    )
    return ref.valueChanges()
  }

  resetOrder(){
    this.myOrder.itemsInCart = []
  }

  joinOrdersBusiness(order$:Observable<Order[]>):Observable<Order[]>{
    let orders: Order[];
    const joinKeys = {};
    return order$.pipe(
      switchMap((o:Order[])=>{
        orders = o
        const sids = Array.from(new Set(orders?.map(v => v.businessID)))
        const storeDocs = sids.map(s => {
          return this.afs.doc(`business/${s}`).valueChanges()
        })
        return storeDocs.length ? combineLatest(storeDocs) : of([])
      }),
      map(arr=>{
        arr.forEach(v => (joinKeys[(<any>v).createAt] = v));
        orders = orders?.map(v => {
          return { ...v, business: joinKeys[v.businessID] };
        });
        return orders
      })
    );
  }

  joinOrderBusiness(order$:Observable<Order>){
    let order: Order;
    let business: Business;
    return order$.pipe(
      switchMap((o:Order)=>{
        order = o
        const storeDoc = this.afs.doc(`business/${order.businessID}`).valueChanges()
        return storeDoc != null ? storeDoc : of(null)
      }),
      map(obj=>{
        business = obj
        order = {...order,business};
        return order
      })
    );
  }

  joinOrderAdvisors(order$:Observable<Order>){
    let order: Order;
    const joinKeys = {};
    return order$.pipe(
      switchMap((o:Order)=>{
        order = o
        const aids = Array.from(new Set(order?.itemsInCart.map(v => v.advisorID)))
        const advisorsDoc = aids.map(s => {
          return this.afs.doc(`users/${s}`).valueChanges()
        })
        return advisorsDoc.length ? combineLatest(advisorsDoc) : of([])
      }),
      map(arr=>{
        arr.forEach(v => v ? (joinKeys[(<any>v).uid] = v) : null);
        order.itemsInCart = order.itemsInCart.map(v => {
          return { ...v, adviser: v.advisorID ? joinKeys[v.advisorID] : null };
        });
        return order
      })
    )
  }
}
