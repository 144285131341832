import { Pipe, PipeTransform } from '@angular/core';
import { Item } from '../models/item';

@Pipe({
  name: 'itemsFilter'
})
export class ItemsPipe implements PipeTransform {

  transform(value: Item[], search: string): Item[] {
    if (!search) {return value}
    let res: Item[] = value.filter(v => {
    	if (!v.name) {return []}
    	return v.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    })
    return res;
  }

}
