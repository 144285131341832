import { Component } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { ThemeService } from './services/theme.service';
import { Geolocation } from '@capacitor/geolocation';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    public platform: Platform,
    public themeService: ThemeService,
    private toastCtrl: ToastController
  ) {
    document.body.classList.toggle('dark',false);
    this.geolocationPermission()
  }

  async geolocationPermission(){
    try{
      const perm = await Geolocation.checkPermissions();
      if(perm.location === 'denied'){
        const toast = await this.toastCtrl.create({
          message: 'Es necesario activar la geolocalización para usar Ataviar.',
          duration: 2000,
          color: 'warning'
        });
        return await toast.present();
      }
    }catch(error){
      const toast = await this.toastCtrl.create({
        message: 'No se ha podido acceder a la geolocalización.',
        duration: 2000,
        color: 'danger'
      });
      return await toast.present();
    }
  }
}
