import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Review } from '../models/review';

@Injectable({
  providedIn: 'root'
})
export class ReviewsService {

  constructor(
		private afs: AngularFirestore
  ) { }

  getBusinessStars(businessId:string): Observable<Review[]> {
    const ref = this.afs.collection<Review>('reviews', ref => ref.where('businessId', '==', businessId) );
    return ref.valueChanges();
  }

  setReview(review: Review) {
    const starPath = `reviews/${review.userId}_${review.businessId}`;
    return this.afs.doc(starPath).set(review)
  }
}
