import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class AdvisorsService {

  /* public advisorUID$: Observable<User[]>
  private businessIdFilter$: BehaviorSubject<number|null> = new BehaviorSubject(0)
  private advisorIdFilter$: BehaviorSubject<string|null> = new BehaviorSubject(null) */

  constructor(
		private afs: AngularFirestore
  ) {
    /* this.advisorUID$ = combineLatest([
      this.businessIdFilter$,
      this.advisorIdFilter$
    ]).pipe(
      switchMap(([businessId,advisorId])=>
        afs.collection<User>('users', ref => {
          let query : firebase.default.firestore.CollectionReference | firebase.default.firestore.Query = ref;
          if (businessId) { query = query.where('businessId', '==', businessId) };
          if (advisorId) { query = query.where('uid', '==', advisorId) };
          return query
        }).valueChanges()
      )
    ); */
  }

  /* filterByBusinessId(businessId: number|null) {
    this.businessIdFilter$.next(businessId); 
  }

  filterByAdvisorId(advisorId: string|null) {
    this.advisorIdFilter$.next(advisorId); 
  } */

  getAdvisorBusiness(businessId:number):Observable<User[]>{
    const ref: AngularFirestoreCollection<User> = this.afs.collection(`users`,ref=>ref.where('businessId','==',businessId))
    return ref.valueChanges()
  }
}
