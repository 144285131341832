import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { Rol } from '../models/rol';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map, switchMap } from 'rxjs/operators';
import { BusinessService } from './business.service';
import { Business } from '../models/business';
import { AuthService } from './auth.service';
import { User } from '../models/user';
import { ScheduleService } from './schedule.service';
import { Schedule } from '../models/schedule';
import { OrderService } from './order.service';

@Injectable({
  providedIn: 'root'
})
export class RolService {

  constructor(
		private afs: AngularFirestore,
    private businessService: BusinessService,
    private authService: AuthService,
    private scheduleService: ScheduleService,
    private orderService: OrderService
  ) {
  }

  getUserRols(uid:string):Observable<Rol[]>{
    const ref: AngularFirestoreCollection<Rol> = this.afs.collection<Rol>(`rols`,ref => ref.where(`uid`,`==`,uid))
    return ref.valueChanges()
  }

  getBusinessRols(businessId:number):Observable<Rol[]>{
    const ref: AngularFirestoreCollection<Rol> = this.afs.collection<Rol>(`rols`,ref => ref.where(`businessId`,`==`,businessId))
    return ref.valueChanges()
  }

  joinBusiness(rol$:Observable<Rol[]>):Observable<Rol[]> {
    let data: Rol[];
    const joinKeys = {};
    return rol$.pipe(
      switchMap((r:Rol[])=>{
        data = r;
        const ids = Array.from(new Set(data?.map(v => v.businessId)))
        const docs = ids.map(id=>{
          return this.businessService.getBusinessById(id)
        })
        return docs.length ? combineLatest(docs) : of([])
      }),
      map(docs=>{
        docs.forEach(v => (joinKeys[(<Business>v).createAt] = v));
        data = data?.map(v => {
          return {...v, business: joinKeys[v.businessId]};
        });
        return data
      })
    );
  }

  joinUsers(rol$:Observable<Rol[]>):Observable<Rol[]> {
    let data: Rol[];
    const joinKeys = {};
    return rol$.pipe(
      switchMap((r:Rol[])=>{
        data = r;
        const uds = Array.from(new Set(data?.map(v => v.uid)))
        const docs = uds.map(id=>{
          return this.authService.getUserByIdObs(id)
        })
        return docs.length ? combineLatest(docs) : of([])
      }),
      map(docs=>{
        docs.forEach(v => (joinKeys[(<User>v).uid] = v));
        data = data?.map(v => {
          return {...v, user: joinKeys[v.uid]};
        });
        return data
      })
    );
  }

  joinSchedule(rol$:Observable<Rol[]>):Observable<Rol[]> {
    let data: Rol[];
    const joinKeys = {};
    return rol$.pipe(
      switchMap((r:Rol[])=>{
        data = r;
        const uds = Array.from(new Set(data?.map(v => v.uid)))
        const docs = uds.map(id=>{
          return this.scheduleService.getUserSchedule(id,this.orderService.myOrder.bookingDate)
        })
        return docs.length ? combineLatest(docs) : of([])
      }),
      map(docs=>{
        docs.forEach((v,i)=>docs[i].length > 0 ? (joinKeys[(<Schedule>v)[0].advisorId] = v) : null)
        data = data?.map((v:Rol) => {
          return {...v, schedules: (joinKeys[v.uid] || [])};
        });
        return data
      })
    );
  }
}
