import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TweenMax, Expo } from "gsap/all";
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-type',
  templateUrl: './type.component.html',
  styleUrls: ['./type.component.scss'],
})
export class TypeComponent implements OnInit {

  constructor(
    private modalCtrl: ModalController,
    public themeService: ThemeService
  ) {
  }

  ngOnInit() {
  }

  ionViewDidEnter(){
    this.enterAnimation()
  }

  setTheme(type:number,className:string){
    this.themeService.setTheme(type,className)
    if(className === 'beauty_theme'){
      TweenMax.to(".beauty_salon", 1, {
        opacity: 0,
        y: '-30%',
        x: '-50%',
        scaleX: 0,
        scaleY: 0,
        ease: Expo.easeInOut
      })
      TweenMax.to(".barber_shop", .3, {
        opacity: 0,
        ease: Expo.easeInOut,
      })
      TweenMax.to(".spa", .3, {
        opacity: 0,
        ease: Expo.easeInOut,
      })
    }
    if(className === 'barber_theme'){
      TweenMax.to(".barber_shop", 1, {
        y: '-30%',
        x: '-50%',
        scaleX: 0,
        scaleY: 0,
        ease: Expo.easeInOut
      })
      TweenMax.to(".beauty_salon", .3, {
        opacity: 0,
        ease: Expo.easeInOut,
      })
      TweenMax.to(".spa", .3, {
        opacity: 0,
        ease: Expo.easeInOut,
      })
    }
    if(className === 'spa_theme'){
      TweenMax.to(".spa", .8, {
        opacity: 0,
        y: '-30%',
        x: '-50%',
        scaleX: 0,
        scaleY: 0,
        ease: Expo.easeInOut
      })
      TweenMax.to(".beauty_salon", .3, {
        opacity: 0,
        ease: Expo.easeInOut,
      })
      TweenMax.to(".barber_shop", .3, {
        opacity: 0,
        ease: Expo.easeInOut,
      })
    }
    setTimeout(() => {
      this.modalCtrl.dismiss()
    }, 800);
  }

  private enterAnimation(){
    TweenMax.from(".beauty_salon", 1, {
      opacity: 0,
      x: -20,
      ease: Expo.easeInOut,
    })
    TweenMax.from(".barber_shop", 1, {
      opacity: 0,
      x: 20,
      ease: Expo.easeInOut
    })
    TweenMax.from(".spa", 1, {
      opacity: 0,
      x: -20,
      ease: Expo.easeInOut
    })
  }

}
