export const POSITIONS: {[index: number]: {"x":string,"y":string,"dark":string,"light":string,"sun":string,"moon":string}} = {
    360:{"x":"-120","y":"0","dark":"#4b89bf","light":"#82b0d9","sun":"1","moon":"0"},
    375:{"x":"-110","y":"0","dark":"#4783b7","light":"#78a7d1","sun":"1","moon":"0"},
    390:{"x":"-100","y":"0","dark":"#4986bb","light":"#74a4ce","sun":"1","moon":"0"},
    405:{"x":"-90","y":"0","dark":"#4985b9","light":"#74a4ce","sun":"1","moon":"0"},
    420:{"x":"-80","y":"0","dark":"#4884b8","light":"#74a4ce","sun":"1","moon":"0"},
    435:{"x":"-70","y":"0","dark":"#4783b7","light":"#70a1cb","sun":"1","moon":"0"},
    450:{"x":"-60","y":"0","dark":"#4681b4","light":"#70a1cb","sun":"1","moon":"0"},
    465:{"x":"-50","y":"0","dark":"#457fb1","light":"#70a1cb","sun":"1","moon":"0"},
    480:{"x":"-40","y":"0","dark":"#437dae","light":"#6d9ec8","sun":"1","moon":"0"},
    495:{"x":"-30","y":"0","dark":"#427bac","light":"#6b9cc6","sun":"1","moon":"0"},
    510:{"x":"-20","y":"0","dark":"#4079a9","light":"#699ac4","sun":"1","moon":"0"},
    525:{"x":"-10","y":"0","dark":"#3f77a7","light":"#6798c2","sun":"1","moon":"0"},
    540:{"x":"0","y":"0","dark":"#3d75a4","light":"#6596c0","sun":"1","moon":"0"},
    555:{"x":"0","y":"10","dark":"#3c73a2","light":"#6394be","sun":"1","moon":"0"},
    570:{"x":"0","y":"20","dark":"#3a719f","light":"#6192bc","sun":"1","moon":"0"},
    585:{"x":"0","y":"30","dark":"#396f9d","light":"#5f90ba","sun":"1","moon":"0"},
    600:{"x":"0","y":"40","dark":"#376d9a","light":"#5d8eb8","sun":"1","moon":"0"},
    615:{"x":"0","y":"50","dark":"#366b98","light":"#5b8cb6","sun":"1","moon":"0"},
    630:{"x":"0","y":"60","dark":"#346995","light":"#598ab4","sun":"1","moon":"0"},
    645:{"x":"0","y":"70","dark":"#336793","light":"#5788b2","sun":"1","moon":"0"},
    660:{"x":"0","y":"80","dark":"#316590","light":"#5586b0","sun":"1","moon":"0"},
    675:{"x":"0","y":"90","dark":"#30638e","light":"#5384ae","sun":"1","moon":"0"},
    690:{"x":"0","y":"100","dark":"#2e618b","light":"#5182ac","sun":"1","moon":"0"},
    705:{"x":"0","y":"110","dark":"#2d5f89","light":"#4f80aa","sun":"1","moon":"0"},
    720:{"x":"0","y":"120","dark":"#2b5d86","light":"#4d7ea8","sun":"1","moon":"0"},
    735:{"x":"0","y":"130","dark":"#2a5b84","light":"#4b7ca6","sun":"1","moon":"0"},
    750:{"x":"0","y":"140","dark":"#285981","light":"#487aa4","sun":"1","moon":"0"},
    765:{"x":"0","y":"150","dark":"#275781","light":"#4678a2","sun":"1","moon":"0"},
    780:{"x":"0","y":"160","dark":"#265680","light":"#4277a6","sun":"1","moon":"0"},//
    795:{"x":"0","y":"170","dark":"#25547d","light":"#3d729d","sun":"1","moon":"0"},
    810:{"x":"0","y":"180","dark":"#24527a","light":"#3b709b","sun":"1","moon":"0"},
    825:{"x":"0","y":"190","dark":"#225077","light":"#396e99","sun":"1","moon":"0"},
    840:{"x":"0","y":"200","dark":"#214e75","light":"#376c97","sun":"1","moon":"0"},
    855:{"x":"0","y":"210","dark":"#1f4c72","light":"#346a95","sun":"1","moon":"0"},
    870:{"x":"0","y":"220","dark":"#1e4a70","light":"#326893","sun":"1","moon":"0"},
    885:{"x":"0","y":"230","dark":"#1c486d","light":"#2f6691","sun":"1","moon":"0"},
    900:{"x":"0","y":"240","dark":"#1b466b","light":"#2d648f","sun":"1","moon":"0"},
    915:{"x":"0","y":"250","dark":"#194468","light":"#2a628d","sun":"1","moon":"0"},
    930:{"x":"0","y":"260","dark":"#184266","light":"#28608b","sun":"1","moon":"0"},
    945:{"x":"0","y":"270","dark":"#164063","light":"#255e89","sun":"1","moon":"0"},
    960:{"x":"0","y":"280","dark":"#153e61","light":"#235c87","sun":"1","moon":"0"},
    975:{"x":"0","y":"290","dark":"#133c5e","light":"#205a85","sun":"1","moon":"0"},
    990:{"x":"0","y":"300","dark":"#113a5c","light":"#1e5884","sun":"!","moon":"0"},
    1005:{"x":"0","y":"310","dark":"#103859","light":"#1b5682","sun":"1","moon":"0"},
    1020:{"x":"0","y":"320","dark":"#0f3657","light":"#195481","sun":"1","moon":"0"},
    //
    1035:{"x":"0","y":"310","dark":"#0d3454","light":"#16527f","sun":"0","moon":"0"},
    1050:{"x":"0","y":"290","dark":"#0b3252","light":"#14507e","sun":"0","moon":"1"},
    1065:{"x":"0","y":"270","dark":"#0a3050","light":"#114d7c","sun":"0","moon":"1"},
    1080:{"x":"0","y":"250","dark":"#092e4d","light":"#0f4b7b","sun":"0","moon":"1"},
    1095:{"x":"0","y":"230","dark":"#072c4a","light":"#0c4879","sun":"0","moon":"1"},
    1110:{"x":"0","y":"210","dark":"#062a49","light":"#0d487c","sun":"0","moon":"1"},
    1125:{"x":"0","y":"190","dark":"#052948","light":"#0c477b","sun":"0","moon":"1"},
    1140:{"x":"0","y":"170","dark":"#042847","light":"#094478","sun":"0","moon":"1"},
    1155:{"x":"0","y":"150","dark":"#032746","light":"#084377","sun":"0","moon":"1"},
    1170:{"x":"0","y":"130","dark":"#032644","light":"#064277","sun":"0","moon":"1"},
    1185:{"x":"0","y":"110","dark":"#022543","light":"#044075","sun":"0","moon":"1"},
    1200:{"x":"0","y":"90","dark":"#012340","light":"#023e73","sun":"0","moon":"1"},
}