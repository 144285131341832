import { Pipe, PipeTransform } from '@angular/core';
import { Business } from '../models/business';

@Pipe({
  name: 'businessFilter'
})
export class BusinessPipe implements PipeTransform {

  transform(value: Business[], search: string): Business[] {
    if (!search) {return value}
    let res: Business[] = value.filter(v => {
    	if (!v.name) {return }
    	return v.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    })
    return res;
  }

}
