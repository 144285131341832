import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RateComponent } from './rate/rate.component';
import { AgmCoreModule } from '@agm/core';
import { TypeComponent } from '../modals/type/type.component';
import { LogoComponent } from './logo/logo.component';
import { OrderProfileComponent } from '../modals/order-profile/order-profile.component';
import { IonicModule } from '@ionic/angular';
import { ScheduleComponent } from '../modals/schedule/schedule.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RateBusinessComponent } from '../modals/rate-business/rate-business.component';
import { ImagePicker } from '@awesome-cordova-plugins/image-picker/ngx';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { WebImageComponent } from '../modals/web-image/web-image.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { BusinessPipe } from '../pipes/business.pipe';
import { LetterBoldPipe } from '../pipes/letter-bold.pipe';
import { ItemsPipe } from '../pipes/items.pipe';
import { ImgComponent } from './img/img.component';
import { CartComponent } from '../modals/cart/cart.component';
import { AdvisorsComponent } from '../modals/advisors/advisors.component';

@NgModule({
  declarations: [
    RateComponent,
    TypeComponent,
    LogoComponent,
    OrderProfileComponent,
    ScheduleComponent,
    RateBusinessComponent,
    WebImageComponent,
    BusinessPipe,
    LetterBoldPipe,
    ItemsPipe,
    ImgComponent,
    CartComponent,
    AdvisorsComponent
  ],
  imports: [
    CommonModule,
    AgmCoreModule,
    IonicModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule
  ],
  exports: [
    RateComponent,
    TypeComponent,
    AgmCoreModule,
    LogoComponent,
    OrderProfileComponent,
    ScheduleComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    RateBusinessComponent,
    WebImageComponent,
    ImageCropperModule,
    BusinessPipe,
    LetterBoldPipe,
    ItemsPipe,
    ImgComponent,
    CartComponent,
    AdvisorsComponent
  ],
  providers: [
    ImagePicker,
    OpenNativeSettings
  ]
})
export class SharedModule { }
