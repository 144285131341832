import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BusinessService } from './business.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private themeSubject: BehaviorSubject<string> = new BehaviorSubject( null );
  public typeSubject: BehaviorSubject<number> = new BehaviorSubject( null );
  public theme: string = null;

  public isOpen:boolean = true

  constructor(
    private businessService: BusinessService
  ) {
    this.themeSubject.subscribe(_ => this.theme = _);
    this.getStorageTheme()
  }

  async getStorageTheme(){
    const storageTheme = await this.getStorageData()
    if(storageTheme){
      this.setTheme(storageTheme.type,storageTheme.className)
    }
  }

  async setTheme(type:number,className:string){
    document.body.classList.toggle('beauty_theme',false);
    document.body.classList.toggle('barber_theme',false);
    document.body.classList.toggle('spa_theme',false);
    document.body.classList.toggle(className,true);
    await this.setStorageTheme({type,className})
    this.themeSubject.next( className );
    this.businessService.business = {}
    this.businessService.filterByType( type )
    this.typeSubject.next(type)
  }

  private async setStorageTheme(data:{
    type:number,className:string}){
    /* if(this.platform.is('cordova')){
      return await this.nativeStorage.setItem('ataviarTheme',item)
    }else{ */
      return localStorage.setItem('ataviarTheme',JSON.stringify(data))
    /* } */
  }

  private async getStorageData():Promise<{
    type:number,className:string}>{
    /* if(this.platform.is('cordova')){
      return await this.nativeStorage.getItem('ataviarTheme')
    }else{ */
      return JSON.parse(localStorage.getItem('ataviarTheme'))
    /* } */
  }
}
