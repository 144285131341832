import { Schedule } from "../models/schedule";
import { User } from "../models/user";

export const validateSchedule = (schedule:Schedule[],startAt:number,duration:number) => {
    const date: Date = new Date(startAt)
    date.setMinutes(date.getMinutes() + duration)

    const OI = startAt
    const OF = date.getTime()
    return schedule.some( (schedule: Schedule)=> {
        const AI = schedule.startAt
        const AF = schedule.endAt
        if((AF > OI && AF < OF) || (AI > OI && AF < OF) || (AI < OF && AI > OI) || (AI <= OI && AF >= OF)){
            return true
        }else{
            return false
        }
    })
}

const objToArray = (obj:object) => {
    return Object.values(obj)
}

export const getRangeTime = (time: number): number[] => {
    let range: number[] = [];
    let datetime: Date = new Date(time);
    let minutes = ((datetime.getHours() * 60) + datetime.getMinutes()) - 90
    for(let i=0;i<6;i++){
        datetime.setHours(0,0,0,0)
        datetime.setMinutes(minutes)
        let aux = new Date(datetime)
        range.push(aux.getTime())
        minutes += 30
    }
    return range
}
