import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { Business, businessGeo } from '../models/business';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import firebase from 'firebase/app';
import 'firebase/firestore';
firebase.initializeApp(environment.firebase);
import * as geofirestore from 'geofirestore';
import { environment } from 'src/environments/environment';
const firestore = firebase.firestore();
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const GeoFirestore = geofirestore.initializeApp(firestore);

@Injectable({
  providedIn: 'root'
})
export class BusinessService {

  public business$: Observable<businessGeo[]>
  public selectedBusines$: Observable<Business[]>
  public business: {[index:number]:Business} = {}
  
  private typeFilter$: BehaviorSubject<number|null> = new BehaviorSubject(null)
  private idFilter$: BehaviorSubject<number|null> = new BehaviorSubject(2);

  public type: number

  constructor(
		private afs: AngularFirestore,
  ) {
    this.business$ = combineLatest(
      this.typeFilter$,
    ).pipe(
      switchMap(([type]) => 
        afs.collection<Business>('business', ref => {
          let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
          if (type) { query = query.where('type', '==', type) };
          return query.where('active','==',true).where('isOpen','==',true)
        }).valueChanges()
      )
    );

    this.selectedBusines$ = combineLatest(
      this.idFilter$
    ).pipe(
      switchMap(([id]) => 
        afs.collection<Business>('business', ref => {
          let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
          if (id) { query = query.where('createAt', '==', id) };
          return query;
        }).valueChanges()
      )
    )
  }

  filterById(id: number|null) {
    this.idFilter$.next(id); 
  }

  filterByType(type: number|null) {
    this.typeFilter$.next(type); 
  }

  getBusinessById(businessId:number):Promise<Business>{
    const ref: AngularFirestoreDocument<Business> = this.afs.doc(`business/${businessId}`)
    return ref.valueChanges().pipe(first()).toPromise()
  }

  getSnapshotTypeBusiness(type:number,center:firebase.firestore.GeoPoint,radius):geofirestore.GeoQuery{
    const geocollection = GeoFirestore.collection('business').where('type','==',type).where('active','==',true).where('isOpen','==',true);
    return geocollection.near({center,radius});
  }
}
