import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Schedule } from '../models/schedule';
import { getRangeTime } from '../utils/shedule';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  public schedule$: Observable<Schedule[]>

  private businessIdFilter$: BehaviorSubject<number> = new BehaviorSubject( null );
  private advisorIdFilter$: BehaviorSubject<string> = new BehaviorSubject( null );
  private activeFilter$: BehaviorSubject<boolean> = new BehaviorSubject( true );
  private startAtFilter$: BehaviorSubject<number> = new BehaviorSubject( null );
  private endAtFilter$: BehaviorSubject<number> = new BehaviorSubject( null );
  private orderIdFilter$: BehaviorSubject<number> = new BehaviorSubject( null );

  constructor(
		private afs: AngularFirestore,
  ) {
    this.schedule$ = combineLatest([
      this.businessIdFilter$,
      this.advisorIdFilter$,
      this.activeFilter$,
      this.startAtFilter$,
      this.endAtFilter$,
      this.orderIdFilter$
    ]).pipe(
      switchMap(([businessId,advisorId,isActive,startAt,endAt,orderId]) =>
        afs.collection<Schedule>(`schedules`, ref => {
          let query : firebase.default.firestore.CollectionReference | firebase.default.firestore.Query = ref;
          if (businessId) { query = query.where(`businessId`,`==`,businessId) }
          if (advisorId) { query = query.where(`advisorId`,`==`,advisorId) }
          if (isActive) { query = query.where(`active`,`==`,isActive) }
          if (startAt) { query = query.where('startAt','in',[startAt]) }
          if (endAt) { query = query.where('endAt','in',[endAt]) }
          if (orderId) { query = query.where(`orderId`,`==`,orderId) }
          return query
        }).valueChanges()
      )
    );
  }

  getUserSchedule(uid:string,startAt:number):Observable<Schedule[]>{
    const startI: number[] = getRangeTime(startAt)
    const ref: AngularFirestoreCollection<Schedule> = this.afs.collection<Schedule>(`schedules`,ref => ref.where(`advisorId`,`==`,uid).where(`startAt`,`in`,startI))
    return ref.valueChanges()
  }

  setSchedule(schedule:Schedule):Promise<void>{
    const ref: AngularFirestoreDocument<Schedule> = this.afs.doc(`schedules/${schedule.startAt}_${schedule.orderId}`);
		return ref.set(schedule, { merge: true })
  }
}
