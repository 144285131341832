import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TweenMax, Expo } from "gsap/all";
import { Order } from 'src/app/models/order';
import { Review } from 'src/app/models/review';
import { OrderService } from 'src/app/services/order.service';
import { ReviewsService } from 'src/app/services/reviews.service';

@Component({
  selector: 'app-rate-business',
  templateUrl: './rate-business.component.html',
  styleUrls: ['./rate-business.component.scss'],
})
export class RateBusinessComponent implements OnInit {

  @Input() businessId: number;
  @Input() userId: string;
  @Input() orderId: number;

  rateValue: number = 0;

  order: Order

  constructor(
    private modalCtrl: ModalController,
    private reviewsService: ReviewsService,
    private orderService: OrderService,
  ) {
  }

  ngOnInit() {
    this.orderService.getOrderById(this.orderId).subscribe(order=>{
      this.order=order
    })
  }

  setValue(value:number){
    this.rateValue = value
    TweenMax.from(".rate_value", .3, {
      y: 40,
      ease: Expo.easeOut,
      opacity: 0,
    });
  }

  async rateBusiness(){
    const storeReview: Review = {
      userId: this.userId,
      businessId: this.businessId,
      value: this.rateValue,
      createAt: Date.now()
    }
    this.order.rate = {
      rated: true,
      value: this.rateValue
    }
    await this.reviewsService.setReview(storeReview)
    await this.orderService.setOrder(this.order)
    this.back()
  }

  back(){
    this.modalCtrl.dismiss()
  }

  ionViewDidEnter(){
    this.showEnterDataAnimation()
  }

  private showEnterDataAnimation(){
    /* BACKGROUND */
    TweenMax.from(".bg_one", .8, {
      y: 80,
      ease: Expo.easeOut,
      delay: .2,
      opacity: 0,
    });
    TweenMax.from(".bg_dos", .8, {
      y: 60,
      ease: Expo.easeOut,
      delay: .3,
      opacity: 0,
    });
    TweenMax.from(".bg_tres", .8, {
      y: 40,
      ease: Expo.easeOut,
      delay: .4,
      opacity: 0,
    });
    TweenMax.from(".bg_arboles", .8, {
      y: 40,
      ease: Expo.easeOut,
      delay: .5,
      opacity: 0,
    });
    TweenMax.from(".bg_nubes", 1.2, {
      y: -40,
      ease: Expo.easeOut,
      delay: .2,
      opacity: 0,
    });
    /* STAR */
    TweenMax.from(".star_1", .8, {
      y: -40,
      ease: Expo.easeOut,
      delay: .2,
      opacity: 0,
    });
    TweenMax.from(".star_2", .8, {
      y: -40,
      ease: Expo.easeOut,
      delay: .3,
      opacity: 0,
    });
    TweenMax.from(".star_3", .8, {
      y: -40,
      ease: Expo.easeOut,
      delay: .4,
      opacity: 0,
    });
    TweenMax.from(".star_4", .8, {
      y: -40,
      ease: Expo.easeOut,
      delay: .5,
      opacity: 0,
    });
    TweenMax.from(".star_5", .8, {
      y: -40,
      ease: Expo.easeOut,
      delay: .6,
      opacity: 0,
    });
    TweenMax.from(".rate_value", .8, {
      y: 40,
      ease: Expo.easeOut,
      delay: .6,
      opacity: 0,
    });
    TweenMax.from(".rate_value_btn", .8, {
      y: 40,
      ease: Expo.easeOut,
      delay: .7,
      opacity: 0,
    });
    //BACK BTN
    TweenMax.from(".close_btn_rate", .8, {
      opacity: 0,
      x: 120,
      ease: Expo.easeOut,
      delay:1
    })
  }

}
