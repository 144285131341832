import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { B_SHEDULE } from 'src/app/mocks/backgrounds';
import { gsap, ScrollTrigger, TweenMax, Expo } from "gsap/all";
import { OrderService } from 'src/app/services/order.service';
import { Order } from 'src/app/models/order';
import { GOOGLEMAP } from 'src/app/mocks/googlemap';

@Component({
  selector: 'app-order-profile',
  templateUrl: './order-profile.component.html',
  styleUrls: ['./order-profile.component.scss'],
})
export class OrderProfileComponent implements OnInit {

  /* TEST */
  lat = -4.0116742;
  lng = -79.2117901;
  styles: google.maps.MapTypeStyle[] = GOOGLEMAP

  //Validador de días en ESP
  aMonths: string[] = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"]
  aDays: string[] = ["DOM", "LUN", "MAR", "MIE", "JUE", "VIE", "SAB"]
  //Vriables de entorno
  backgrounds = B_SHEDULE
  background: string
  @Input() orderId: number;
  order:Order

  constructor(
    private modalCtrl: ModalController,
    private orderService: OrderService
  ) {
    this.background = this.backgrounds[Math.floor(Math.random() * this.backgrounds.length)];
  }

  ngOnInit() {
    const sourceBusiness = this.orderService.getOrderById(this.orderId)
    const sourceAdvisors = this.orderService.joinOrderAdvisors(sourceBusiness)
    this.orderService.joinOrderBusiness(sourceAdvisors).subscribe(order=>{
      this.order=order
      setTimeout(() => {
        TweenMax.to(".advisor_photo_container", .3, {
          ease: Expo.easeInOut,
          delay: .3,
          opacity: 1,
        });
      }, 100);
    })
  }

  back(){
    this.modalCtrl.dismiss()
  }

  ionViewDidEnter(){
    this.showEnterDataAnimation()
  }

  private showEnterDataAnimation(){
    /* INFO */
    TweenMax.from(".date_order_profile", .8, {
      x: -20,
      ease: Expo.easeInOut,
      opacity: 0,
    });
    TweenMax.from(".hours_order_profile", .8, {
      x: 20,
      ease: Expo.easeInOut,
      delay: .2,
      opacity: 0,
    });
    TweenMax.from(".status_order_profile", .8, {
      y: 20,
      ease: Expo.easeInOut,
      delay: .4,
      opacity: 0,
    });
    /* CLOCK */
    TweenMax.from(".service_info_order_profile", .8, {
      y: -20,
      ease: Expo.easeInOut,
      delay: .4,
      opacity: 0,
    });
    //BACK BTN
    TweenMax.from(".close_btn_order_profile", .8, {
      opacity: 0,
      x: 120,
      ease: Expo.easeOut,
    })
  }

  dateFormat(milliseconds:number):Date{
    return new Date(milliseconds)
  }

}
